import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import { auto } from '@popperjs/core';
import React from 'react';
import SwiperInterface, { FreeMode, Navigation, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Accordion } from '../Accordion';
import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Button, ButtonVariant } from '../Button';
import { Container } from '../Container';
import { PromoProductContentType } from '../contentTypes';
import { CustomRadio } from '../CustomRadio';
import { t } from '../i18n/i18n';
import { IconName, IconSize } from '../Icon';
import { PriceBlock } from '../PriceBlock';
import { ProductReviews } from '../ProductReviews';
import { Section } from '../Section';
import { SliderB } from '../slider/SliderB';
import { SliderButton } from '../slider/SliderButton';
import { Page } from './Page';

export interface ProductPagePros {}

export const ProductPage = (props: ProductPagePros) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });

  const promoContentCode = product?.attributes?.find(
    (attribute) => attribute.typeCode === 'MIA_PROMO_CONTENT_CODE'
  )?.value;

  const enableShowMoreSkuFrom = 14;
  const { showModal } = b2x.useModals();

  const [showMoreSku, setShowMoreSku] = React.useState<boolean>();

  const handleShowMoreSkuButtonClick = React.useCallback(() => {
    setShowMoreSku(!showMoreSku);
  }, [showMoreSku]);

  const [skusDivContainer, scrollToSkusDivContainer] = b2x.useScrollTo(-180);

  const { headerHeight, isFooterCopyrightVisible, isHeaderFirstRowVisible } = useAppContext();
  const [reviewsRef, scrollToReviews] = b2x.useScrollTo(-(headerHeight ?? 0));

  return (
    <Page className="product-page" noPaddingTop thingsToLoadBeforePageReady={[product]}>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <>
              <Section className="mb-5">
                <b2x.AddToCartFormHelper product={product} scope="product">
                  {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                    <Container>
                      <b2x.Row className="">
                        <b2x.Col size={12}>
                          <div className="py-3 py-lg-4">
                            <Breadcrumb />
                          </div>
                        </b2x.Col>
                        <b2x.Col size={{ lg: 6, xs: 12 }}>
                          <div className="position-relative">
                            <div className="btn-wishlist-container position-absolute top-0 end-0 p-3">
                              <b2x.WishlistButtonHelper product={product} sku={selectedSku}>
                                {({ handleWishlistButtonClick, inWishlist }) => (
                                  <Button
                                    className="text-primary"
                                    iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 30 }}
                                    onClick={handleWishlistButtonClick}
                                    variant="blank"
                                  />
                                )}
                              </b2x.WishlistButtonHelper>
                            </div>
                            <ProductImageSlider
                              product={product}
                              selectedProductVariant={selectedProductVariant}
                              selectedSku={selectedSku}
                            />
                          </div>
                          {promoContentCode && <ProductBanner promoContentCode={promoContentCode} />}
                        </b2x.Col>
                        <b2x.Col className="px-xxl-4" size={{ lg: 6, xs: 12, xxl: 6 }}>
                          {(selectedProductVariant.tags?.includes('MIA_NEW') ||
                            selectedProductVariant.tags?.includes('MIA_LIMITED')) && (
                            <b2x.Row className="mb-2">
                              {selectedProductVariant.tags.map((tag) => (
                                <b2x.Col className="sticker-container mb-1 pe-0" key={tag} size={{ lg: auto, xs: 12 }}>
                                  <span className="rounded-1 px-2 bg-mint fw-bold text-uppercase small">
                                    {tag === 'MIA_NEW' ? t('misc.MIA_NEW') : t('misc.MIA_LIMITED')}
                                  </span>
                                </b2x.Col>
                              ))}
                            </b2x.Row>
                          )}
                          {product.name && <h1 className="h2 fw-semi-bold">{b2x.formatHtml(product.name)}</h1>}

                          <h6 className="fw-semi-bold mb-3">
                            {selectedSku?.name && b2x.formatHtml(` ${selectedSku.name}`)}
                          </h6>

                          <b2x.SummaryReviewsPreview
                            className="mb-3"
                            productId={selectedProductVariant.id}
                            productSummaryReviews={selectedProductVariant.summaryReviews}
                            scrollTo={scrollToReviews}
                          />

                          {selectedProductVariant.descriptionShort && (
                            <h2 className="description mb-3 h6">
                              {b2x.formatHtml(selectedProductVariant.descriptionShort)}
                            </h2>
                          )}
                          {selectedSku?.code && (
                            <p className="text-gray-400 text-uppercase mb-4">
                              {t('product.code')}: {selectedSku.code}
                            </p>
                          )}
                          {fieldsHelper.skus.formFields.length > 1 && (
                            <div className="skus-container mb-4" ref={skusDivContainer} style={{ maxWidth: '430px' }}>
                              <b2x.FormGroup {...fieldsHelper.skus.formGroup} label={undefined} noMarginBottom>
                                <b2x.Row gap={2}>
                                  {fieldsHelper.skus.formFields.map(({ radio, sku }, index) => (
                                    <b2x.Col
                                      key={sku.id}
                                      size={'auto'}
                                      style={{
                                        display:
                                          !showMoreSku && index >= enableShowMoreSkuFrom ? 'none' : 'inline-block',
                                      }}
                                    >
                                      <b2x.Radio {...radio} inline noMargin>
                                        <CustomRadio sku={sku} />
                                      </b2x.Radio>
                                    </b2x.Col>
                                  ))}
                                  {fieldsHelper.skus.formFields.length > enableShowMoreSkuFrom && (
                                    <b2x.Col size={'auto'}>
                                      <Button
                                        className="rounded-circle p-2"
                                        iconEnd={{ name: !showMoreSku ? 'plus' : 'minus', size: 14 }}
                                        onClick={handleShowMoreSkuButtonClick}
                                        variant="outline-primary"
                                      />
                                    </b2x.Col>
                                  )}
                                </b2x.Row>
                              </b2x.FormGroup>
                            </div>
                          )}
                          <div className="product-price-container mb-1 d-flex align-items-center">
                            <PriceBlock
                              classNames={{ crossedOutPrice: 'fs-5', price: 'fs-4', specialPrice: 'fs-4' }}
                              gap={3}
                              priceHelper={priceHelper}
                            />
                            {product.offers && product.offers.length > 0 && (
                              <>
                                {product.offers.map((offer) => (
                                  <div className="sticker-promo-container px-2 small" key={offer.id}>
                                    <span className="rounded-1 px-2 bg-promo text-white">
                                      {offer.shortDescription}
                                      <b2x.Button
                                        className={'btn-link text-white ps-1'}
                                        iconStart={{ name: 'info', size: 14 }}
                                        // eslint-disable-next-line react/jsx-no-bind
                                        onClick={() => {
                                          showModal({
                                            children: offer.description,
                                            size: 'default',
                                            title: offer.shortDescription ? offer.shortDescription : '',
                                          });
                                        }}
                                        variant="blank"
                                      />
                                    </span>
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                          {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
                            <div className="small mb-3">
                              <b2x.BestPrice priceHelper={priceHelper} />
                            </div>
                          )}
                          <div className="d-flex mb-4">
                            <b2x.FormGroup
                              {...fieldsHelper.quantity.formGroup}
                              className="text-center me-3"
                              label={undefined}
                              noMarginBottom
                            >
                              <b2x.Select
                                {...fieldsHelper.quantity.select}
                                className="text-center"
                                includeEmptyOption={false}
                                placeholder={undefined}
                                style={{ maxWidth: '85px' }}
                              />
                            </b2x.FormGroup>
                            {/* <b2x.Button {...fieldsHelper.buttons.submit} /> */}
                            <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                              fieldsHelper={fieldsHelper}
                              selectedSku={selectedSku}
                            />
                          </div>
                          <div className="mt-3 mb-1">
                            <b2x.InstallmentPaymentBanner amount={priceHelper.price} />
                          </div>
                          <ProductAccordion
                            selectedSku={productContext.selectedSku}
                            selectedVariant={productContext.selectedProductVariant}
                          />
                          <b2x.Portal>
                            <b2x.ProductStickyFooter
                              fieldsHelper={fieldsHelper}
                              formik={formik}
                              isVisible={!isHeaderFirstRowVisible && !isFooterCopyrightVisible}
                              priceHelper={priceHelper}
                              product={product}
                              scrollToElement={scrollToSkusDivContainer}
                              selectedProductImage={selectedProductVariant.image}
                              selectedSku={selectedSku}
                            />
                          </b2x.Portal>
                        </b2x.Col>
                      </b2x.Row>
                    </Container>
                  )}
                </b2x.AddToCartFormHelper>
              </Section>
              <b2x.RelatedProducts productId={productContext.selectedProductVariant.id}>
                {({ fetching, relatedProducts }) =>
                  fetching ? (
                    <b2x.Loading />
                  ) : (
                    relatedProducts &&
                    relatedProducts.length > 0 && (
                      <Section className="mb-3 mb-lg-5">
                        <Container>
                          <h3 className="h2 fw-semi-bold text-center mb-5">{t('product.relatedProduct.title')}</h3>
                        </Container>
                        <b2x.Listing name="Product page - Related products" products={relatedProducts}>
                          <SliderB products={relatedProducts} />
                        </b2x.Listing>
                      </Section>
                    )
                  )
                }
              </b2x.RelatedProducts>
              <ProductReviews innerRef={reviewsRef} product={product} />
            </>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface ProductImageSliderProps {
  product: b2x.ProductApiDto;
  selectedProductVariant: b2x.ProductApiDto;
  selectedSku?: b2x.SkuApiDto;
}

const ProductImageSlider = ({ product, selectedProductVariant, selectedSku }: ProductImageSliderProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  const [thumbsSwiper, setThumbsSwiper] = React.useState<SwiperInterface>();
  const [gallerySwiper, setGallerySwiper] = React.useState<SwiperInterface>();

  const skuVideoUrl = selectedSku?.attributes?.find((attribute) => attribute.typeCode === 'MIA_VIDEO_URL_SKU')?.value;
  const skuVideoThumbnail = selectedSku?.attributes?.find(
    (attribute) => attribute.typeCode === 'MIA_VIDEO_IMAGE_URL_SKU'
  )?.value;

  const variantVideoUrl = selectedProductVariant.attributes?.find(
    (attribute) => attribute.typeCode === 'MIA_VIDEO_URL'
  )?.value;
  const variantVideoThumbnail = selectedProductVariant.attributes?.find(
    (attribute) => attribute.typeCode === 'MIA_VIDEO_IMAGE_URL'
  )?.value;

  const gallery = React.useMemo(() => {
    const slides = [];
    const initialSlide = 0;

    if (selectedSku?.image) {
      slides.push({
        src: selectedSku.image.src,
        thumbnail: selectedSku.image.src,
        type: 'image',
        uId: selectedSku.image.id,
      });
    } else {
      slides.push({
        src: selectedProductVariant.image?.src,
        thumbnail: selectedProductVariant.image?.src,
        type: 'image',
        uId: selectedProductVariant.image?.id,
      });
    }

    if (selectedSku?.alternativeImages) {
      selectedSku.alternativeImages.forEach((image) =>
        slides.push({ src: image.src, thumbnail: image.src, type: 'image', uId: image.id })
      );
    } else if (selectedProductVariant.alternativeImages) {
      selectedProductVariant.alternativeImages.forEach((image) =>
        slides.push({ src: image.src, thumbnail: image.src, type: 'image', uId: image.id })
      );
    }

    if (skuVideoUrl && skuVideoThumbnail) {
      slides.push({
        src: skuVideoUrl,
        thumbnail: skuVideoThumbnail,
        type: 'video',
        uId: skuVideoUrl,
      });
    } else if (variantVideoUrl && variantVideoThumbnail) {
      slides.push({
        src: variantVideoUrl,
        thumbnail: variantVideoThumbnail,
        type: 'video',
        uId: variantVideoUrl,
      });
    }

    return { initialSlide, slides };
  }, [
    selectedProductVariant.alternativeImages,
    selectedProductVariant.image?.id,
    selectedProductVariant.image?.src,
    selectedSku?.alternativeImages,
    selectedSku?.image,
    variantVideoThumbnail,
    variantVideoUrl,
    skuVideoThumbnail,
    skuVideoUrl,
  ]);

  React.useEffect(() => {
    if (selectedSku) {
      gallerySwiper?.slideTo(0);
    }
  }, [gallerySwiper, selectedSku]);

  return (
    <div className="product-image-slider">
      <div className="gallery-container mb-3">
        <b2x.Lightbox>
          <Swiper
            className="border border-gray-200 rounded overflow-hidden h-100"
            modules={[FreeMode, Thumbs]}
            onSwiper={setGallerySwiper}
            slidesPerView={1}
            spaceBetween={0}
            thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
            watchSlidesProgress
          >
            {gallery.slides.map((slide, index) => (
              <SwiperSlide
                // eslint-disable-next-line react/no-array-index-key
                key={`product-slide-${index}`}
              >
                {(slideData) => (
                  <div className="product-slide-image-container d-flex align-items-center justify-content-center">
                    {slide.type === 'image' && slide.src ? (
                      <b2x.LightboxItem bcomImageId={slide.uId} className="d-block w-100" src={slide.src}>
                        <b2x.Image
                          alt={product.name}
                          aspectRatio={b2x.appConfig.productImageAspectRatio}
                          fluid
                          src={slide.src}
                        />
                      </b2x.LightboxItem>
                    ) : (
                      slide.type === 'video' &&
                      slideData.isActive && (
                        <b2x.DeprecatedVideoFromContent
                          autoplay
                          controls
                          src={{ xs: { preview: { url: slide.thumbnail }, video: slide.src } }}
                        />
                      )
                    )}
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </b2x.Lightbox>
      </div>
      <div className="thumbnail-container mb-3 position-relative">
        <Swiper
          allowSlideNext
          allowSlidePrev
          className="flex-grow-1 auto-width"
          freeMode
          modules={[Thumbs, Navigation, FreeMode]}
          navigation={{
            nextEl: sliderNextButton.current,
            prevEl: sliderPrevButton.current,
          }}
          onSwiper={setThumbsSwiper}
          slidesPerView={'auto'}
          spaceBetween={16}
          watchSlidesProgress
        >
          {gallery.slides.map((slide, index) => (
            <SwiperSlide
              className="border rounded overflow-hidden"
              // eslint-disable-next-line react/no-array-index-key
              key={`product-thumbnail-${index}`}
            >
              {(slideData) =>
                slide.thumbnail && (
                  <div style={{ height: 120, width: 120 }}>
                    <b2x.Image
                      alt={`${product.image} thumbnail`}
                      aspectRatio={b2x.appConfig.productImageAspectRatio}
                      fluid
                      height={120}
                      src={slide.thumbnail}
                      width={120}
                    />
                  </div>
                )
              }
            </SwiperSlide>
          ))}
        </Swiper>
        <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
        <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
      </div>
    </div>
  );
};

interface ProductBannerProps {
  promoContentCode: string;
}

const ProductBanner = ({ promoContentCode }: ProductBannerProps) => {
  const content = b2x.useContent<PromoProductContentType>(promoContentCode);
  return (
    <>
      {content && (
        <div className="mb-3 my-lg-3 position-relative">
          <b2x.router.Link className="stretched-link" to={content.body.link}>
            <b2x.ImageFromContentV1 {...content.body.img} fluid />
          </b2x.router.Link>
          {content.body.copy?.title && (
            <div className="position-absolute w-100 h-100 top-0 left-0 d-flex justify-content-center align-items-center">
              <div className="container">
                <div className="row">
                  <div className="col-8 offset-4">
                    <div className="px-2" style={{ color: content.body.copy.textColor }}>
                      <div className="h4 fw-bold mb-1">{b2x.formatHtml(content.body.copy.title)}</div>
                      <div className="small">{b2x.formatHtml(content.body.copy.content)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

interface ProductAccordionProps {
  selectedSku?: b2x.SkuApiDto;
  selectedVariant: b2x.ProductApiDto;
}

const ProductAccordion = ({ selectedSku, selectedVariant }: ProductAccordionProps) => {
  const ingredients =
    selectedSku?.attributes?.find((attribute) => attribute.typeCode === 'MIA_ingredienti') ??
    selectedVariant.attributes?.find((attribute) => attribute.typeCode === 'MIA_ingredienti') ??
    undefined;

  return (
    <Accordion
      className="product-accordion"
      id="product-accordion"
      itemBodyClassName="px-0"
      itemButtonClassName="fs-6 fw-semi-bold px-0"
    >
      {selectedVariant.description && (
        <b2x.AccordionItem id="product-description" show title={t('product.description')}>
          {b2x.formatHtml(selectedVariant.description)}
          {selectedVariant.assembled && (
            <div className="small my-2">
              {selectedVariant.assembledComponents && selectedVariant.assembledComponents.length > 0 && (
                <ul className="list-unstyled">
                  {selectedVariant.assembledComponents.map((assembledComponent, index) => {
                    const sku = assembledComponent.componentSkus?.at(0)?.sku;
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <li className="lh-sm" key={`assembledComponent-${index}`}>
                        <b>{assembledComponent.defaultQty}x</b> {sku?.product?.name}
                        {sku?.measurement &&
                          ` (${sku.measurement.value} ${sku.measurement.measurementUnit?.toLocaleLowerCase()})`}
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          )}
          {selectedVariant.attributes?.find(({ typeCode }) => typeCode && typeCode.includes('MIA_ICONATECNICA')) && (
            <div className="mt-4 mb-2 hstack gap-2 gap-md-3">
              {selectedVariant.attributes.map(
                (attribute) =>
                  attribute.typeCode &&
                  attribute.typeCode.includes('MIA_ICONATECNICA') && (
                    <div className="product-icon" key={attribute.typeCode}>
                      <b2x.Image src={attribute.value} />
                    </div>
                  )
              )}
            </div>
          )}
        </b2x.AccordionItem>
      )}
      {ingredients && (
        <b2x.AccordionItem id="product-ingredients" title={ingredients.title ?? ''}>
          {b2x.formatHtml(ingredients.value)}
        </b2x.AccordionItem>
      )}
      {selectedVariant.attributes?.map(
        (attribute) =>
          attribute.typeCode !== 'MIA_ingredienti' &&
          attribute.typeCode !== 'MIA_VIDEO_URL' &&
          attribute.typeCode !== 'MIA_VIDEO_IMAGE_URL' &&
          attribute.typeCode !== 'MIA_PROMO_CONTENT_CODE' &&
          !attribute.typeCode.includes('MIA_ICONATECNICA') && (
            <b2x.AccordionItem id={attribute.typeCode} key={attribute.typeCode} title={attribute.title ?? ''}>
              {b2x.formatHtml(attribute.value)}
            </b2x.AccordionItem>
          )
      )}
    </Accordion>
  );
};
