import { Resource } from '../i18n';

export const de: Resource = {
  account: {
    coupons: {
      active: 'Aktiv',
      code: 'Code',
      description: 'Beschreibung',
      empty: 'Kein Gutschein',
      expired: 'Abgelaufen',
      expiryDate: 'Ablaufdatum',
      numberOfUses: 'Anzahl der Verwendungen',
      status: 'Status',
    },
    create: 'Erstellen Sie Ihr Konto',
    creditCards: {
      addCreditcard: 'Kreditkarte oder Debitkarte hinzufügen',
      noCardRegistered: 'Keine Kreditkarte registriert',
    },
    edit: 'Ändern',
    emptyWishlist: 'Keine Produkte zur Wunschliste hinzugefügt',
    info: {
      additionalInformation: {
        children: {
          birthdate: 'Geburtsdatum',
          name: 'Name',
          sex: 'Geschlecht',
          surname: 'Nachname',
          title: 'Kind',
        },
        document: {
          authorityDescription: 'Beschreibung der Behörde',
          authorityType: 'Art der Behörde',
          districtName: 'Name des Bezirks',
          districtType: 'Art des Bezirks',
          documentNumber: 'Nummer des Dokuments',
          documentType: 'Art des Dokuments',
          releaseDate: 'Ausstellungsdatum',
          title: 'Dokument',
        },
        other: 'Sonstiges',
        title: 'Zusätzliche Informationen',
      },
      birthdate: 'Geburtsdatum',
      businessType: {
        label: 'Art der Tätigkeit',
        options: {
          COMPANY: 'Betrieb',
          PROPRIETORSHIP: 'Einzelfimra / Freiberufler',
        },
      },
      company: 'Firmenname',
      editPassword: 'Passwort ändern',
      email: 'E-Mail',
      loginData: 'Login-Daten',
      name: 'Name',
      pec: 'Zertifizierte E-Mail-Adresse',
      personalAddresses: 'Adresse',
      personalData: 'Persönliche Daten',
      phone: 'Mobiltelefon',
      profile: {
        label: 'Profil',
        options: {
          BUSINESS: 'Business',
          PRIVATE: 'Privat',
        },
      },
      secondaryPhone: 'Anderes Telefon',
      sex: {
        options: {
          F: 'Frau',
          M: 'Mann',
          O: 'Nicht näher angegeben',
        },
        title: 'Geschlecht',
      },
      surname: 'Nachname',
      taxCode: 'Steuernummer',
      title: 'Rechnungsdaten',
      uniqueCode: 'SDI-Code',
      vatNumber: 'USt-IDNr.',
    },
    insertCredentials: 'Wenn Sie registriert sind, geben Sie Ihre E-Mail-Adresse und Ihr Passwort ein',
    login: 'Melden Sie sich an',
    logout: 'Melden Sie sich ab',
    loyalty: {
      pointsBalance: 'Ihr Punktestand',
      pointsTable: {
        awardsLabel: 'Drücken',
        couponLabel: 'Rabattcode',
        footerText: 'Verwenden Sie beim Kauf den Rabattcode',
        pointsLabel: 'Punkte',
        ptLabel: 'pt',
      },
      shopping: 'Beginnen Sie mit dem Einkaufen',
    },
    newCustomer: 'Sind Sie ein neuer Kunde?',
    orders: {
      details: {
        closeOrderDetails: 'Schließen',
        code: 'Code',
        color: 'Farbe',
        date: 'Datum',
        invoiceDownload: 'Rechnung herunterladen',
        invoiceNotReady: 'Rechnung noch nicht fertig',
        paymentAddress: 'Zahlung',
        price: 'Preis',
        product: 'Produkt',
        quantity: 'Menge',
        receiverShipping: 'Empfänger',
        review: 'Zusammenfassung',
        shippingAddress: 'Versand',
        showOrderDetails: 'Bestellungsdetails anzeigen',
        size: 'Größe:',
        status: 'Status',
        total: 'Gesamtbetrag',
        totalAmount: 'Zu zahlender Gesamtbetrag: ',
        totalProducts: 'Produkte insgesamt:',
        totalShipping: 'Versand:',
        trackingCode: 'Versandverfolgung',
        unitPrice: 'Einheitspreis',
      },
      noOrders: 'Es sind derzeit keine Bestellungen vorhanden',
      status: {
        arrived: 'Zugestellt',
        cancelled: 'Gelöscht',
        preparing: 'In Vorbereitung',
        readyToShipping: 'Fertig für den Versand',
        shipping: 'Versendet',
        waitPayment: 'In Erwartung der Zahlung',
        waitingAcceptance: 'In der Annahme',
      },
      trackOrdersState: 'Verfolgen Sie den Status Ihrer Bestellungen',
    },
    passwordForgotten: 'Passwort vergessen?',
    register: 'Registrieren Sie sich',
    sectionDisabled: 'Abschnitt deaktiviert',
    taxBenefits: {
      alerts: {
        danger: 'Die Dokumente wurden nicht angenommen',
        success: 'Benutzer zugelassen',
        warning: 'Die Dokumente werden derzeit geprüft',
      },
    },
    userRegistered: 'Sind Sie ein registrierter Kunde?',
    welcomeMessage: 'Hallo {{name}}, entdecken Sie Ihren persönlichen Bereich',
    wishlist: 'Zur Wunschliste hinzufügen',
  },
  alert: {
    CART_DIFFERENT_SKU_PRICE: '',
    CART_MINIMUM_COST: 'Mindestbetrag im Warenkorb nicht erreicht',
    CART_MIN_CAR_SELLER_VIOLATED: '',
    CART_REGION_NOT_REACHABLE_EXTRA_FRESH: '',
    CART_SKU_NOT_SHIPPABLE_IN_COUNTRY_VIOLATED: 'Dieses Produkt kann in dem ausgewählten Land nicht verkauft werden',
    GUEST_CHECKOUT_NOT_ALLOWED: '',
    INVOICE_MANDATORY_MISSING_DATA: 'Rechnung wurde angefordert, aber es fehlen Rechnungsdaten',
    MISSING_BILLING_ADDRESS: 'Rechnungsadresse fehlt',
    MISSING_EMAIL: 'E-Mail fehlt',
    MISSING_PAYMENT_METHOD: 'Die Zahlungsart muss noch ausgewählt werden',
    MISSING_SHIPPING_ADDRESS: 'Versandadresse fehlt',
    MISSING_SHIPPING_PROFILE: 'Versandprofil nicht ausgewählt',
    UNSOLICITED_INVOICE: 'Achtung, für diese Bestellung wurde keine Rechnung angefordert',
    WAREHOUSEITEM_NOT_IN_STOCK: '',
    WAREHOUSESKU_NOT_AVAILABLE: 'Produkt nicht verfügbar',
  },
  api: {
    error: {
      AddingSkuNotAllowed: 'Dieses Produkt kann nicht gekauft werden',
      AddingSkuNotCompatible: '',
      AddingSkuNotSellable: 'Das Produkt, das Sie hinzufügen möchten, ist nicht verkäuflich.',
      CouponAlreadyUsedException: 'Coupon bereits verwendet',
      CouponCartRangeException: 'Der Gutschein gilt nicht für diesen Warenkorb',
      CouponInvalidException:
        'Der eingegebene Code stimmt mit keinem Gutschein überein oder ist nicht auf diesen Warenkorb anwendbar',
      CouponLoggedGuestException: 'Um den Gutschein zu nutzen, melden Sie sich an oder registrieren Sie sich unter',
      CustomerCannotSetFeedbackException:
        'Wir konnten unter Ihren Einkäufen keine Bestellungen finden, die dieses Produkt enthalten',
      CustomerEmailAlreadyExistException: 'E-Mail wird bereits verwendet',
      CustomerEmailNotValid: 'Eingegebene E-Mail ungültig',
      CustomerEmailNotVerifiedException:
        'Um fortfahren zu können, müssen Sie Ihre Registrierung über den Link bestätigen, den Sie in der E-Mail finden, die Sie nach der Registrierung erhalten.',
      CustomerNotFoundException:
        'Die eingegebene Adresse erscheint nicht in unserem System. Bitte überprüfen Sie sie und versuchen Sie es erneut.',
      CustomerUsernameAlreadyExistException: 'Der Benutzername wird bereits verwendet',
      DataReferenceException: 'Interner Fehler',
      DepositPaymentCardNoRemainingCreditException: 'Kein Restguthaben',
      DepositPaymentCardNotFoundException: 'Code nicht anerkannt',
      DepositPaymentCardNotPermittedOnCartException:
        'Es ist nicht möglich, die Geschenkkarte für den Kauf einer anderen Karte zu verwenden.',
      EJBException: 'Interner Fehler',
      LoginException: 'Benutzername und Passwort ungültig',
      LoginUserNotFoundException: 'E-Mail nicht gefunden',
      SystemException: 'Interner Fehler',
      TaxCodeCheckDigitException:
        'Die eingegebene Steuernummer ist ungültig. Bitte überprüfen Sie den eingegebenen Wert noch einmal und versuchen Sie es erneut.',
      TaxCodeLenghtException:
        'Die eingegebene Steuernummer ist ungültig. Bitte überprüfen Sie den eingegebenen Wert noch einmal und versuchen Sie es erneut.',
      VatNumberCheckDigitException:
        'Die eingegebene USt-IDNr. ist ungültig. Bitte überprüfen Sie den eingegebenen Wert noch einmal und versuchen Sie es erneut.',
      VatNumberLenghtException:
        'Die eingegebene USt-IDNr. ist ungültig. Bitte überprüfen Sie den eingegebenen Wert noch einmal und versuchen Sie es erneut.',
      title: 'Fehler',
      usernotlogged: 'Sitzung abgelaufen',
    },
  },
  assembledProduct: {
    addToAssembly: 'Zur Baugruppe hinzufügen', // da revisionare
    addToCart: 'Zum Einkaufswagen hinzufügen', // da revisionare
    completeTheAssembly: 'Vollständige Montage', // da revisionare
    discountUpTo: 'Bis zu {{percentage}}% Rabatt', // da revisionare
    discountUpToMini: 'Bis zu {{percentage}}%', // da revisionare
    freeShipping: 'Kostenloser Versand', // da revisionare
    removeFromAssembly: 'Artikel löschen', // da revisionare
    summary: {
      actions: {
        addToCart: 'Kaufen', // da revisionare
        next: 'Weiter', // da revisionare
        previous: 'Zurück', // da revisionare
      },
      badges: {
        freeShipping: 'Kostenloser Versand', // da revisionare
        totalDiscount: 'Sie sparen {{value}}', // da revisionare
      },
      infoMessage: {
        addAtLeast: '',
        addAtLeast_one: 'Mindestens ein Produkt hinzufügen', // da revisionare
        addAtLeast_other: 'Mindestens {{count}} Produkte hinzufügen', // da revisionare
        addMoreProducts: '',
        addMoreProducts_one: 'Ein weiteres Produkt hinzufügen, um fortzufahren', // da revisionare
        addMoreProducts_other: 'Weitere {{count}} Produkte hinzufügen, um fortzufahren', // da revisionare
        completeAllStepToContinue: 'Um fortzufahren, müssen Sie alle Schritte ausführen', // da revisionare
        completeStepToContinue: 'Um fortzufahren, müssen Sie den Schritt', // da revisionare
      },
      status: '{{productAdded}}/{{maxProduct}} hinzugefügte Produkte', // da revisionare
      subtitle: 'Zusammenfassung Ihrer Versammlung', // da revisionare
      title: 'Ihr Gesamt', // da revisionare
      total: 'Insgesamt', // da revisionare
      yourAssembly: 'Ihre Montage', // da revisionare
    },
    totalPieces: '{{total}} Stücke', // da revisionare
  },
  bankAccountDetails: {
    bankName: 'Bank',
    bicSwiftCode: 'BIC/SWIFT',
    ibanCode: 'IBAN',
    ownerName: 'Empfänger',
  },
  cart: {
    badges: {
      discountValue: 'Sie sparen {{value}}', // da revisionare
      freeShipping: 'Kostenloser Versand', // da revisionare
    },
    empty: {
      description: 'Beginnen Sie mit der Suche nach etwas',
      title: 'Ihr Warenkorb ist leer',
    },
    loyaltyPointsEarned: 'Mit Ihrem Warenkorb können Sie <strong>{{loyaltyPointsEarned}} Treuepunkte</strong> sammeln.',
    modal: {
      shippingCountry: {
        alert: 'Achtung! Wenn Sie das Land wechseln, wird Ihr Warenkorb geleert',
        title: 'Wollen Sie das Versandland ändern?',
      },
    },
    paymentMethods: {
      title: 'Verfügbare Zahlungsarten',
    },
    productTile: {
      composedOf: 'Bestehend aus',
      removeItem: 'Artikel entfernen',
      sendToWishlist: 'Zur Wunschliste hinzufügen',
    },
    proocedToCart: 'Zum Warenkorb',
    quantity: 'Menge',
    summary: {
      additional: {
        cashOnDelivery: 'Zahlung bei Zustellung',
        title: 'Zuschläge',
      },
      continueShopping: 'Fahren Sie mit dem Einkauf fort',
      coupons: {
        removeCoupon: 'Entfernen',
        title: 'Geben Sie den Rabattcode ein (fakultativ)',
      },
      freeShipping: 'Gratis',
      freeShippingFrom: 'Kostenloser Versand ab {{freeShippingFromAmount}}',
      giftCard: 'Geschenkkarten',
      proocedToCheckout: 'Fortfahren',
      shippingCountry: {
        edit: 'Ändern',
        title: 'Versandland',
      },
      shippingProfiles: {
        title: 'Versand',
      },
      subtotal: 'Zwischensumme',
      title: 'Ihre Bestellung',
      toFreeShipping: 'Es fehlen <strong>{{toFreeShippingAmount}}</strong> für kostenlosen Versand',
      toFreeShippingComplete: 'Sie haben den <b>kostenlosen Versand</b> erreicht.',
      totalBillingCost: 'Gesamtbetrag (einschl. MwSt.)',
      totalCost: 'Zu zahlender Gesamtbetrag',
      totalDiscount: 'Rabatte',
      totalProductsCost: 'Kosten für Produkte',
    },
    title: 'Mein Warenkorb',
  },
  checkout: {
    backToCart: 'Zurück zum Warenkorb',
    billing: {
      addNewAddress: 'Neue Adresse hinzufügen',
    },
    cancel: 'Abbrechen',
    cannotStartPayment:
      'Oops, in Ihrem Warenkorb ist etwas schief gelaufen, bitte überprüfen Sie die Artikel und fahren Sie dann fort',
    edit: 'Ändern',
    invoice: {
      notRequested: 'Sie haben keine Rechnung angefordert',
      requested: 'Sie haben eine Rechnung angefordert',
    },
    shipping: {
      addNewAddress: 'Neue Adresse hinzufügen',
    },
    step: {
      billingAddress: {
        subTitle: 'Stimmt mit der Versandadresse überein',
        title: 'Zahlungsadresse',
      },
      email: {
        alert: {
          buttons: {
            label: '',
          },
          title: '.',
        },
        insertCredentials: 'Geben Sie Ihre Zugangsdaten ein und schließen Sie die Anmeldung ab.',
        login: 'Melden Sie sich an',
        orderAs: 'Sie bestellen als',
        subTitle1: ' Schließen Sie Ihre Bestellung schneller ab.',
        subTitle2: 'Melden Sie sich mit Ihrem Konto an',
        title: 'E-Mail',
      },
      paymentMethod: {
        subTitle: 'Wählen Sie die Zahlungsart',
        title: 'Zahlungsart',
      },
      shippingAddress: {
        title: 'Versandadresse',
      },
    },
    summary: {
      additional: {
        cashOnDelivery: 'Zahlung bei Zustellung',
        title: 'Zuschläge',
      },
      cartItem: {
        edit: 'Ändern',
        title: 'Sie kaufen gerade',
      },
      coupon: {
        add: 'Hinzufügen',
        edit: 'Ändern',
        title: 'Gutschein',
      },
      giftCard: {
        add: 'Hinzufügen',
        edit: 'Ändern',
        title: 'Geschenkkarte',
      },
      shipping: {
        title: 'Versand',
      },
      shippingCountry: {
        title: 'Versandland',
      },
      subtotal: 'Zwischensumme',
      title: 'Ihre Bestellung',
      totalBillingCost: 'Gesamtbetrag (einschl. MwSt.)',
      totalCost: 'Zu zahlender Gesamtbetrag',
      totalProductsCost: 'Produkte insgesamt:',
    },
    thankYou: {
      cashOnDelivery: {
        amountToPayOnDelivery: 'Bei Zustellung zu bezahlender Betrag',
      },
    },
  },
  contactUs: {
    customerOrder: {
      label: 'Brauchen Sie Hilfe bei dieser Bestellung?',
      placeholder: 'Wählen Sie Ihre Bestellung aus',
    },
    notSatisfied: {
      label: 'Sie haben die von Ihnen gesuchte Antwort nicht gefunden? Kontaktieren Sie uns',
    },
    satisfied: {
      label: 'Haben Sie die von Ihnen gesuchte Antwort gefunden?',
      no: 'nein',
      yes: 'ja',
    },
    subject: {
      label: 'Was für eine Information brauchen Sie?',
      placeholder: 'Wählen Sie eine Option',
    },
  },
  customerCare: {
    contact: 'Kontaktieren Sie uns',
    help: 'Können wir Ihnen helfen?',
    select: {
      label: 'Was für eine Information brauchen Sie?',
      optionLabel: 'Wählen Sie eine Option',
    },
    service: 'Im Bereich Kundenservice finden Sie schnelle und einfache Hilfe',
  },
  error: {
    pageNotFound: 'Oops Wir haben die gesuchte Seite nicht gefunden',
  },
  fixedPagesName: {
    account: 'account',
    cart: 'cart',
    'change-password': 'change-password',
    checkout: 'checkout',
    error: 'error',
    'forgot-password': 'forgot-password',
    home: 'home',
    login: 'login',
    newsletter: 'newsletter',
    'order-ko': 'order-ko',
    'order-ok': 'order-ok',
    register: 'register',
    search: 'search',
    sitemap: 'sitemap',
    template: 'template',
    test: 'test',
    'unsubscribe-newsletter': 'unsubscribe-newsletter',
    whois: 'whois',
  },
  footer: {
    paymentsMethod: 'Zahlungsarten',
  },
  form: {
    addToCartForm: {
      buttons: {
        availabilityEmailNotification: {
          addLong: { label: 'Benachrichtigen Sie mich, sobald verfügbar' },
          addShort: { label: 'Benachrichtigen Sie mich' },
          remove: { label: 'Benachrichtigung löschen' },
        },
        cancel: { label: 'Abbrechen' },
        reset: { label: 'Reset' },
        submit: { label: 'In den Warenkorb legen' },
        submitButNotAvailable: { label: 'Nicht verfügbar' },
      },
      fields: {
        giftCard: {
          message: {
            label: 'Nachricht',
            placeholder: '',
          },
          receiverEmail: {
            label: 'E-Mail des Empfängers',
            placeholder: '',
          },
          receiverName: {
            label: 'Name des Empfängers',
            placeholder: '',
          },
          senderName: {
            label: 'Ihr Name',
            placeholder: '',
          },
          value: {
            placeholder: 'Wählen Sie eine Option',
          },
        },
        quantity: {
          label: 'Menge',
          placeholder: 'Wählen Sie eine der Optionen',
        },
        skuAttributes: {
          Gift_card_valore: {
            placeholder: 'Wählen Sie den Wert',
          },
        },
        skus: {
          label: 'Skus',
          placeholder: 'Wählen Sie einen Sku',
        },
      },
      misc: {
        productVariants: 'Format',
      },
      modal: {
        title: '',
      },
      outcome: {
        failure: {
          AddingSkuNotCompatible: {
            body: 'Dieses Produkt kann nicht zusammen mit anderen Produkten in Ihrem Warenkorb verschickt werden. Möchten Sie fortfahren, indem Sie den aktuellen Warenkorb leeren?', // da revisionare
            buttons: {
              no: { label: 'Nein' },
              yes: { label: 'Ja' },
            },
            title: 'Es tut uns leid',
          },
        },
        success: {
          body: 'Produkt zum Warenkorb hinzugefügt',
          buttons: {
            continueShopping: { label: 'Fahren Sie mit dem Einkauf fort' },
            goToCart: { label: 'Zum Warenkorb' },
          },
          title: 'Produkt zum Warenkorb hinzugefügt',
        },
      },
    },
    addressForm: {
      account: {
        defaultBilling: {
          label: 'Als Standard- Zahlungsadresse festlegen',
        },
        defaultShipping: {
          label: 'Als Standard- Versandadresse festlegen',
        },
      },
      address: {
        addressLine1: {
          label: 'Adresse',
          placeholder: '',
        },
        addressLine2: {
          label: 'Wohnungsnr., Stock, c/o usw.',
          placeholder: '',
        },
        addressLine3: {
          label: '',
          placeholder: '',
        },
        alternativePhone: {
          label: 'Alternative Telefonnummer',
          placeholder: '',
        },
        city: {
          label: 'Ort',
          placeholder: '',
        },
        civicNumber: {
          label: 'Hausnummer',
          placeholder: '',
        },
        country: {
          label: 'Nation',
          placeholder: '',
        },
        latitude: {
          label: 'Breitengrad',
          placeholder: '',
        },
        longitude: {
          label: 'Längengrad',
          placeholder: '',
        },
        middleName: {
          label: 'Zweiter Name',
          placeholder: '',
        },
        name: {
          label: 'Name',
          placeholder: '',
        },
        phone: {
          label: 'Telefon',
          placeholder: '',
        },
        province: {
          label: 'Provinz',
          placeholder: 'Provinz',
        },
        region: {
          label: 'Region',
          placeholder: '',
        },
        shippingToAlert: 'Sie versenden nach {{locale}}, gehen Sie zurück, um Ihr Versandland zu ändern.',
        surname: {
          label: 'Nachname',
          placeholder: '',
        },
        zipCode: {
          label: 'PLZ',
          placeholder: 'PLZ',
        },
      },
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        edit: {
          label: 'Ändern',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Hinzufügen',
        },
      },
      copyFromBilling: {
        label: 'Verwenden Sie dieselbe Zahlungsadresse',
      },
      copyFromShipping: {
        label: 'Verwenden Sie dieselbe Versandadresse',
      },
      copyToBilling: {
        label: 'Auch als Zahlungsadresse festlegen',
      },
      copyToShipping: {
        label: 'Auch als Versandadresse festlegen',
      },
      editAddressTitle: 'Adresse ändern',
      invoice: {
        data: {
          company: {
            label: 'Firmenname',
            placeholder: '',
          },
          pec: {
            label: 'Zertifizierte E-Mail',
            placeholder: '',
          },
          taxCode: {
            label: 'Steuernummer',
            placeholder: '',
          },
          uniqueCode: {
            label: 'SDI-Code',
            placeholder: '',
          },
          vatNumber: {
            label: 'USt-IDNr.',
            placeholder: '',
          },
        },
        request: {
          label: 'Möchten Sie eine Rechnung?',
        },
        type: {
          label: 'Art des Kunden',
          options: {
            company: 'Betrieb',
            freelance: 'Einzelfimra / Freiberufler',
            privatePerson: 'Privat',
          },
        },
      },
      modal: {
        title: '',
      },
      newAddressTitle: 'Geben Sie eine neue Adresse ein',
    },
    cartAddressFromAddressBookForm: {
      buttons: {
        cancel: { label: 'Abbrechen' },
        editAddress: { label: 'Adresse ändern' },
        newAddress: { label: 'Neue Adresse hinzufügen' },
        reset: { label: 'Reset' },
        submit: { label: 'Bestätigen' },
      },
    },
    cartEmailForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          checkout: {
            label: 'E-Mail ändern',
          },
          whoIs: {
            label: 'Fahren Sie mit der Bestellung fort',
          },
        },
      },
      fields: {
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
        newsletterConsent: {
          label: '',
          radios: {
            false: '',
            true: '',
          },
        },
        privacy: {
          label: 'Ich stimme der Verarbeitung der personenbezogenen Daten zu',
        },
      },
      handlers: {
        registeredEmail: {
          body: '',
          buttons: {
            cancel: {
              label: '',
            },
            submit: {
              label: '',
            },
          },
          title: '',
        },
      },
      modal: {
        title: '',
      },
    },
    cartGenericCouponForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Anwenden',
        },
      },
      fields: {
        code: {
          label: '',
          placeholder: 'Geben Sie den Code ein',
        },
      },
      modal: {
        title: '',
      },
    },
    cartPaymentMethodsForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Bestätigen',
        },
      },
      modal: {
        title: '',
      },
    },
    cartPersonalCouponsForm: {
      misc: {
        noCoupon: '',
      },
      modal: {
        title: '',
      },
    },
    changePasswordByTokenForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Anfrage absenden',
        },
      },
      fields: {
        confirmNewPassword: {
          label: 'Neues Passwort bestätigen',
          placeholder: 'Neues Passwort bestätigen',
        },
        newPassword: {
          label: 'Neues Passwort',
          placeholder: 'Neues Passwort',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Der Vorgang der Passwortänderung wurde erfolgreich abgeschlossen',
          title: 'Passwortänderung bestätigen',
        },
      },
    },
    changePasswordForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Anfrage absenden',
        },
      },
      fields: {
        confirmNewPassword: {
          label: 'Neues Passwort bestätigen',
          placeholder: 'Neues Passwort bestätigen',
        },
        currentPassword: {
          label: 'Aktuelles Passwort',
          placeholder: 'Aktuelles Passwort',
        },
        newPassword: {
          label: 'Neues Passwort',
          placeholder: 'Neues Passwort',
        },
      },
      modal: {
        title: 'Passwortänderung',
      },
      outcome: {
        success: {
          body: 'Der Vorgang der Passwortänderung wurde erfolgreich abgeschlossen',
          title: 'Passwortänderung bestätigen',
        },
      },
    },
    consentsForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Speichern',
        },
      },
      fields: {
        marketing: {
          label: 'Zustimmung zum Marketing',
          radios: {
            false: 'Nein',
            true: 'Ja',
          },
        },
        newsletter: {
          label: 'Zustimmung zum Newsletter',
          radios: {
            false: 'Nein',
            true: 'Ja',
          },
        },
        profiling: {
          label: 'Zustimmung zur Profilierung',
          radios: {
            false: 'Nein',
            true: 'Ja',
          },
        },
      },
      modal: {
        title: '',
      },
    },
    customerCareForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Absenden',
        },
      },
      fields: {
        email: {
          label: 'E-Mail',
          placeholder: '',
        },
        message: {
          label: 'Nachricht',
          placeholder: '',
        },
        name: {
          label: 'Name',
          placeholder: '',
        },
        phoneNumber: {
          label: 'Telefon',
          placeholder: '',
        },
        privacy: {
          label: 'Ich erkläre, dass ich die Datenschutzerklärung gelesen und akzeptiert habe.',
          placeholder: '',
        },
        surname: {
          label: 'Nachname',
          placeholder: '',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Nachricht korrekt abgeschickt',
          title: 'Nachricht absenden',
        },
      },
    },
    customerForm: {
      birthdate: {
        errors: {
          minAge: 'Geben Sie ein gültiges Geburtsdatum an',
          minAgeWithDate: '',
          minAgeWithDate_one: 'Sie müssen mindestens {{count}} Jahr alt sein',
          minAgeWithDate_other: 'Sie müssen mindestens {{count}} Jahre alt sein',
        },
        label: 'Geburtsdatum',
      },
      businessType: {
        label: 'Wie ist Ihre Tätigkeit registriert',
        options: {
          company: 'Betrieb',
          proprietorship: 'Einzelfimra / Freiberufler',
        },
        placeholder: 'Wählen Sie eine Option',
      },
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          registrationLabel: 'Registrieren Sie sich',
          updateLabel: 'Aktualisieren',
          updateRequiredFieldsLabel: 'Vervollständigen Sie das Profil',
        },
      },
      children: {
        birthdate: {
          errors: {
            minAge: 'Geben Sie ein gültiges Geburtsdatum an',
            minAgeWithDate: '',
            minAgeWithDate_one: 'Sie müssen mindestens {{count}} Jahr alt sein',
            minAgeWithDate_other: 'Sie müssen mindestens {{count}} Jahre alt sein',
          },
          label: 'Geburtsdatum',
          placeholder: 'Geburtsdatum',
        },
        buttons: {
          add: {
            label: 'Fügen Sie ein Kind hinzu',
          },
          remove: {
            label: 'Entfernen Sie das Kind',
          },
        },
        formGroup: {
          label: 'Kinder (min: {{min}} max: {{max}} gegenwärtig: {{current}})',
        },
        limitDisclaimer: 'Sie haben die Höchstgrenze erreicht',
        name: {
          label: 'Name',
          placeholder: 'Name',
        },
        sex: {
          label: 'Geschlecht',
          options: {
            female: 'Weiblich',
            male: 'Männlich',
            other: 'Ich ziehe es vor, keine Angaben zu machen',
          },
          placeholder: 'Wählen Sie eine Option',
        },
        surname: {
          label: 'Nachname',
          placeholder: 'Nachname',
        },
      },
      company: {
        label: 'Firmenname',
        placeholder: 'Firmenname',
      },
      document: {
        authorityDescription: {
          label: 'Beschreibung der Behörde',
          placeholder: 'Beschreibung der Behörde',
        },
        authorityType: {
          label: 'Art der Behörde',
          options: {
            consulate: 'Konsulat',
            financeOffice: 'Finanzamt',
            foreignState: 'Auslandsstaat',
            motorization: 'Kfz-Zulassungsstelle',
            municipality: 'Gemeinde',
            other: 'Sonstiges',
            prefecture: 'Präfektur',
            unknown: 'Unbekannt',
          },
          placeholder: 'Art der Behörde',
        },
        districtName: {
          label: 'Name des Bezirks',
          placeholder: 'Name des Bezirks',
        },
        districtType: {
          label: 'Art des Bezirks',
          placeholder: 'Art des Bezirks',
        },
        documentNumber: {
          label: 'Nummer des Dokuments',
          placeholder: 'Nummer des Dokuments',
        },
        documentType: {
          label: 'Art des Dokuments',
          options: {
            drivingLicense: 'Führerschein',
            identityCard: 'Personalausweis',
            passport: 'Reisepass',
          },
          placeholder: 'Art des Dokuments',
        },
        label: 'Dokument',
        releaseDate: {
          label: 'Ausstellungsdatum',
          placeholder: 'Ausstellungsdatum',
        },
      },
      email: {
        label: 'E-Mail',
        placeholder: 'E-Mail',
      },
      enableBusinessData: {
        formGroup: {
          label: 'Möchten Sie Angaben zu Ihrer Tätigkeit hinzufügen?',
        },
        options: {
          no: 'Nein',
          yes: 'Ja',
        },
      },
      enableChildren: {
        formGroup: {
          label: 'Möchten Sie Kinder hinzufügen?',
        },
        options: {
          no: 'Nein',
          yes: 'Ja',
        },
      },
      enableDocument: {
        formGroup: {
          label: 'Möchten Sie ein Dokument hinzufügen?',
        },
        options: {
          no: 'Nein',
          yes: 'Ja',
        },
      },
      marketingConsent: {
        label: 'Marketing',
      },
      modal: {
        title: 'Persönliche Daten',
      },
      name: {
        label: 'Name',
        placeholder: 'Name',
      },
      newsletterConsent: {
        label: 'Newsletter',
      },
      password: {
        label: 'Passwort',
        placeholder: 'Passwort',
      },
      pec: {
        label: 'Zertifizierte E-Mail-Adresse',
        placeholder: 'Zertifizierte E-Mail-Adresse',
      },
      phoneNumbers: {
        buttons: {
          add: {
            label: 'Telefon hinzufügen',
          },
          remove: {
            label: 'Telefon entfernen',
          },
        },
        label: 'Telefon',
        placeholder: 'Telefon',
      },
      privacyConsent: {
        label: 'Datenschutz',
      },
      profile: {
        label: 'Profil',
        options: {
          business: 'Business',
          private: 'Privat',
        },
        placeholder: 'Wählen Sie eine Option',
      },
      profilingConsent: {
        label: 'Profilierung',
      },
      sex: {
        label: 'Geschlecht',
        options: {
          female: 'Frau',
          male: 'Mann',
          other: 'Ich ziehe es vor, keine Angaben zu machen',
        },
        placeholder: 'Wählen Sie eine Option',
      },
      surname: {
        label: 'Nachname',
        placeholder: 'Nachname',
      },
      taxCode: {
        label: 'Steuernummer',
        placeholder: 'Steuernummer',
      },
      uniqueCode: {
        label: 'SDI-Code',
        placeholder: 'SDI-Code',
      },
      vatNumber: {
        label: 'USt-IDNr.',
        placeholder: 'USt-IDNr.',
      },
    },
    emailToCartForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Fahren Sie mit der Bestellung fort',
        },
      },
      modal: {
        title: '',
      },
    },
    exampleForm: {
      modal: {
        title: '',
      },
    },
    forgotPasswordForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Anfrage absenden',
        },
      },
      fields: {
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Vorgang erfolgreich abgeschlossen\n\nWir haben Ihnen eine E-Mail mit entsprechenden Anweisungen geschickt.',
          title: 'Anfrage zur Passwortrücksetzung',
        },
      },
    },
    giftCardForm: {
      buttons: {
        balanceCheck: {
          label: 'Überprüfen Sie den Saldo',
        },
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Verwenden',
        },
      },
      fields: {
        code: {
          label: 'Geschenkkarte',
          placeholder: 'Geben Sie die Nummer der Geschenkkarte ein',
        },
      },
    },
    localeForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Weiter zu',
        },
      },
      fields: {
        locale: {
          label: 'Sprache',
        },
        shippingCountry: {
          label: 'Land',
        },
      },
      modal: {
        title: '',
      },
    },
    loginForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Login',
        },
      },
      fields: {
        password: {
          label: 'Passwort',
          placeholder: 'Passwort',
        },
        username: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
      },
      modal: {
        title: '',
      },
    },
    newsletterForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Unterschreiben',
        },
      },
      fields: {
        email: {
          label: 'E-Mail',
          placeholder: 'Geben Sie Ihre E-Mail-Adresse ein',
        },
        name: {
          label: 'Name',
          placeholder: 'Geben Sie Ihren Namen ein',
        },
        newsletterConsent: {
          label: '',
        },
        surname: {
          label: 'Nachname',
          placeholder: 'Geben Sie Ihren Nachnamen ein',
        },
      },
      modal: {
        title: '',
      },
      outcome: {
        success: {
          body: 'Glückwunsch! Von nun an sind auch Sie Teil des Newsletters!',
          buttons: {
            ok: {
              label: 'Ok',
            },
          },
          title: 'Anmeldung zum Newsletter erfolgreich abgeschlossen',
        },
      },
    },
    orderGuestForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Suchen',
        },
      },
      fields: {
        email: {
          label: 'E-Mail',
          placeholder: 'E-Mail',
        },
        orderCode: {
          label: 'Bestellungscode',
          placeholder: 'Bestellungscode',
        },
      },
      modal: {
        title: '',
      },
    },
    restartPaymentForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Jetzt bezahlen',
        },
      },
      fields: {
        checkbox: {
          label: '',
        },
      },
      modal: {
        title: '',
      },
    },
    reviewForm: {
      buttons: {
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Hinterlassen Sie eine Rezension',
        },
      },
      fields: {
        message: {
          label: 'Fügen Sie einen Kommentar hinzu',
          placeholder: 'Schreiben Sie Ihre Rezension',
        },
        rating: {
          label: 'Wie hat Ihnen <strong>{{productName}}</strong> gefallen?',
        },
      },
      modal: {
        title: 'Sagen Sie uns Ihre Meinung!',
      },
      outcome: {
        failure: {
          generic: {
            body: 'Wir konnten unter Ihren Einkäufen keine Bestellungen finden, die dieses Produkt enthalten',
            title: 'Es tut uns leid',
          },
        },
        success: {
          body: 'Ihre Rezension wird veröffentlicht, nachdem sie von unserem Kundendienst geprüft wurde.',
          title: 'Danke für Ihr Feedback!',
        },
      },
    },
    shopSearchForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Suchen',
        },
      },
    },
    simpleSearchForm: {
      fields: {
        text: {
          label: 'Suchen',
          placeholder: 'Suchen',
        },
      },
    },
    startPaymentForm: {
      buttons: {
        cancel: {
          label: 'Abbrechen',
        },
        reset: {
          label: 'Reset',
        },
        submit: {
          label: 'Kaufen',
        },
      },
      fields: {
        checkbox: {
          label: '',
        },
      },
      modal: {
        title: '',
      },
    },
    taxBenefitsForm: {
      buttons: {
        browseFile: 'Legen Sie ein Dokument bei',
        cancel: 'Abbrechen',
        reset: {
          label: 'Reset',
        },
        submit: 'Dokumentation speichern',
      },
      documentValidation: {
        approved: 'Dokument für gültig erklärt',
        awating: 'Dokument wird derzeit geprüft.',
        rejected: 'Dokument abgelehnt Laden Sie ein neues gültiges Dokument',
      },
      documentation: {
        label: 'Laden Sie die Dokumentation herunter',
      },
      taxCode: {
        label: 'Steuernummer',
        placeholder: 'Steuernummer',
      },
    },
    updateCustomerRequiredFieldsForm: {
      modal: {
        title: '',
      },
    },
  },
  header: {
    cartSummary: '',
    cartSummary_one: 'Zusammenfassung der Bestellung ({{count}} Artikel)',
    cartSummary_other: 'Zusammenfassung der Bestellung ({{count}} Artikel)',
    freeShippingFrom: 'Kostenloser Versand in {{locale}} von {{from}}',
    sendTo: 'Land {{country}} | {{locale}}',
  },
  i18nTest: 'Dies ist ein Test von b2x-react',
  misc: {
    account: 'Account',
    addAddress: 'Adresse hinzufügen',
    addToCart: 'Hinzufügen',
    amount: 'Betrag',
    availabilityEmailNotification: 'Benachrichtigen Sie mich',
    availabilityNotification: 'Wir werden Sie benachrichtigen, wenn verfügbar',
    back: 'Zurück',
    backToHome: 'Zurück zur Homepage',
    bestPrice: {
      info: 'Info',
      modal: {
        lowestPrice: 'Niedrigster Preis in den letzten 30 Tagen',
        priceToday: 'Was kostet der Artikel heute',
        title: 'Historische Entwicklung des Artikelpreises',
      },
      priceLabel: 'Letzter niedrigster Preis',
    },
    categories: 'Kategorien',
    changeCountryMessage: 'Achtung! Wenn Sie das Land wechseln, wird Ihr Warenkorb geleert',
    checkout: 'Zur Kasse',
    code: 'Code',
    customerForm: {
      registrationSuccessful: {
        modal: {
          body: 'Danke für Ihr Abonnement', //da revisionare
          title: 'Registrierung abgeschlossen', //da revisionare
        },
      },
    },
    customerService: 'Kundenbetreuung',
    directions: 'Anleitungen',
    discoverMore: 'Entdecken Sie mehr',
    discoverOurCollection: 'Entdecken Sie die Kollektion',
    discoverOurProducts: 'Entdecken Sie unsere Produkte',
    doubleOptIn: {
      body: 'Ihre E-Mail-Adresse wurde erfolgreich überprüft. Bitte melden Sie sich an, um mit dem Browsen zu beginnen.',
      title: 'Konto überprüft',
    },
    edit: '',
    fileInput: {
      label: 'Wählen Sie eine Datei',
      placeholder: 'Keine Datei ausgewählt',
      uploading: 'uploading...',
    },
    filter: 'Filtern',
    filterAndOrderBy: 'Filtern und ordnen nach',
    filterBy: 'Filtern nach',
    filterYourSearchResults: 'Ihre Suchergebnisse filtern',
    followUs: 'Folgen Sie uns',
    fromPrice: 'von {{value}}',
    giftCard: {
      balance: {
        activation: 'Aktivierung',
        expiration: 'Ablaufdatum',
        title: 'Geschenkkarte Detail',
        value: 'Saldo',
      },
      label: 'Geschenkkarte',
      message: 'Die Nachricht anzeigen',
      receiver: {
        label: 'An',
        mail: 'E-Mail',
      },
      sender: {
        label: 'Von',
        mail: 'E-Mail',
      },
      value: 'Wert',
    },
    giftProduct: 'Geschenk',
    gratis: 'Gratis',
    invalid_form_values: 'Prüfen Sie alle Felder, bevor Sie fortfahren',
    invoice: {
      company: 'Firmenname',
      pec: 'Zertifizierte E-Mail',
      taxCode: 'Steuernummer',
      uniqueCode: 'SDI-Code',
      vatNumber: 'USt-IDNr.',
    },
    locale: {
      box: {
        title: {
          country: 'Wählen Sie das Land und die Sprache aus',
          language: 'Sprache auswählen',
        },
      },
    },
    loginRegister: 'Anmelden/Registrieren',
    myOrders: 'Meine Aufträge',
    newProductSticker: 'New',
    newsletterTitle: 'Newsletter',
    or: '',
    orderBy: 'Ordnen nach',
    orderCode: 'Bestellungscode',
    passwordInput: {
      hide: 'Verbergen',
      show: 'Anzeigen',
    },
    payPalCheckout: {
      advanced: {
        privacyNotice: '',
      },
      capture: {
        errorModal: {
          body: '',
          title: '',
        },
      },
      form: {
        buttons: {
          cancel: '',
          submit: '',
        },
        fields: {
          cvv: {
            placeholder: '',
          },
          expiry: {
            placeholder: '',
          },
          name: {
            placeholder: '',
          },
          number: {
            placeholder: '',
          },
        },
        state: {
          invalid: '',
        },
      },
    },
    payments: 'Zahlungen',
    preferences: 'Das könnte Sie interessieren',
    price: '{{value}}',
    pricePerShape: '{{price}} je {{shape}}',
    pricePerUnit: '{{price}} je {{measurementUnit}}',
    productNotAvailable: 'Nicht verfügbar',
    productsPicker: {
      progress: '',
      progress_one: '',
      progress_other: '',
      select: '',
      selected: '',
    },
    readMore: 'Weiterlesen',
    register: 'Registrieren Sie sich jetzt',
    relatedProducts: 'Das könnte Sie auch interessieren',
    removeFilters: 'Alle entfernen',
    searchItemResult: '',
    searchItemResult_one: '{{count}} Ergebnis',
    searchItemResult_other: '{{count}} Ergebnisse',
    shipment: 'Sendungen und Rücksendungen',
    showLess: 'Schließen',
    showMore: 'Alles lesen',
    sizeGuide: 'Anleitung zu den Größen',
    storeName: '',
    time: {
      days: '',
      days_one: 'Tag',
      days_other: 'Tage',
      hours: '',
      hours_one: 'Stunde',
      hours_other: 'Stunden',
      minutes: '',
      minutes_one: 'Minute',
      minutes_other: 'Minuten',
      seconds: '',
      seconds_one: 'Sekunde',
      seconds_other: 'Sekunden',
    },
    unsubscribeNewsletterByToken: {
      pending: 'Abmeldung vom Newsletter läuft',
      success: 'Abmeldung vom Newsletter erfolgreich abgeschlossen!',
      title: 'Newsletter',
    },
    vat: '(MwSt. {{value}}%)',
    viewAll: 'Alle ansehen',
    welcomeMessage: 'Hallo {{name}}',
    wishlist: 'Wunschzettel',
    youSearchedFor: 'Sie haben gesucht:',
  },
  modal: {
    geolocation: {
      content: '{{storeName}} wird Sie bitten, Ihren Standort für eine bessere Nutzung des Dienstes anzugeben.',
      title: 'Geolokalisierung {{storeName}}',
    },
  },
  paymentMethod: {
    ALIAS: 'Zahlung über Alias',
    ANTICIPATO_BONIFICO: 'Vorausgezahlt durch Banküberweisung',
    ANTICIPATO_CC: 'Vorausgezahlt mit Kreditkarte',
    B2X_DIRECT: '',
    BANCOMAT_PAY: 'BANCOMAT Pay',
    BANK_TRANSFER: 'Banküberweisung',
    BNL: 'Kreditkarte über BNL',
    CALL_CENTER: 'Call center',
    CASH: 'CASH',
    CASH_ON_DELIVERY: 'Nachnahme',
    CMCIC: '',
    EASYPAY_CC: '',
    EASYPAY_MB: '',
    EASYPAY_MBWAY: '',
    ECPAY: '',
    FATTURA_30: 'Rechnung 30 Tage',
    FATTURA_60: 'Rechnung 60 Tage',
    FIDELITY_CARD: '',
    GESTPAY: '',
    GESTPAY_MYBANK: '',
    GIFT_CARD: 'Geschenkkarte',
    KEYCLIENT: 'Kreditkarte',
    KLARNA: '',
    MULTIBANCO: '',
    MYBANK: '',
    NOT_NECESSARY_PAYMENT: 'Keine Zahlung notwendig',
    NOT_SETTED_PAYMENT: 'Zahlung nicht eingestellt',
    PAYPAL: 'PayPal',
    PAYPAL_V2: '',
    PAY_ON_PHYSICAL_SHOP: '',
    POS: 'Kreditkarte mit POS vor Ort',
    POSTE_BPIOL: 'Poste BPIOL',
    POSTE_BPOL: 'Poste BPOL',
    POSTE_CC: 'Kreditkarte über Poste',
    POSTE_PAY: 'PostePay',
    RIBA60: 'RiBa 60',
    RIBA_30: 'RiBa 30',
    RIMESSA_DIRETTA: 'Direktüberweisung',
    SATISPAY: 'Satispay',
    SCALAPAY: 'Zahlung über Scalapay',
    SETEFI: 'Kreditkarte',
    SETEFI_MASTERPASS: 'Masterpass',
    THIRD_PARTIES: 'PostePay',
    WANNME_BT: '',
    WANNME_CC: '',
    WANNME_PAY7: '',
    WANNME_RECURRENT: '',
    WORLDLINE_EASY_CHECKOUT: 'Debitkarte',
  },
  product: {
    caloriesPer100g: 'Kalorien je 100 g',
    code: 'Produktcode',
    cookingMinutes: 'Minuten - Kochzeit',
    description: 'Beschreibung',
    details: 'Produktdetails',
    dryingTime: 'Zertifizierte Trocknungsstunden',
    features: 'Merkmale',
    freeShippingFrom: 'Kostenloser Versand ab {{freeShippingFromAmount}}',
    minutesOfCookingAlDente: 'Minuten - bissfest',
    promo: 'Promo',
    relatedProduct: {
      title: 'Das könnte Ihnen auch gefallen',
    },
    selectSize: 'Wählen Sie eine Größe aus',
    share: 'Teilen',
    size: 'Format',
    sizeGuide: 'Anleitung zu den Größen',
    temporary: {
      shipping: 'Schnelle Lieferung und einfache Rückgabe innerhalb von 30 Tagen.',
      sizeGuide: 'Sie kennen die Größe nicht?',
    },
    usageAndMaintenance: 'Benutzung und Instandhaltung',
  },
  reviews: {
    buttons: {
      reviewForFirst: 'Schreiben Sie als Erster eine Rezension für dieses Produkt',
      showMore: 'Noch mehr anzeigen',
      signIn: 'Melden Sie sich an',
      signUp: 'Registrieren Sie sich',
      writeReview: 'Hinterlassen Sie eine Rezension',
    },
    messages: {
      customer: 'Teilen Sie Ihre Erfahrungen mit diesem Produkt.',
      guest: 'Melden Sie sich an oder registrieren Sie sich, um eine Rezension zu hinterlassen',
      withReviwes: 'Haben Sie dieses Produkt bereits ausprobiert?',
      withoutReviews:
        'Haben Sie dieses Produkt bereits ausprobiert? Teilen Sie uns zuerst Ihre Meinung mit und lassen Sie uns wissen, was Sie davon halten!',
    },
    misc: {
      emptyList: 'Es liegen keine Rezensionen vor',
      filterBy: 'Filtern nach',
      review: '',
      review_one: '{{count}} Rezension',
      review_other: '{{count}} Rezensionen',
      star: '',
      star_one: 'Stern',
      star_other: 'Sterne',
      writeReview: 'Hinterlassen Sie eine Rezension',
    },
    review: {
      notVerified: 'Rezension nicht überprüft',
      purchasedOn: 'Gekauft {{date}}',
      reviewedOn: 'Rezensiert {{date}}',
    },
    titles: {
      withReviwes: 'Alle Rezensionen',
      withoutReviews: 'Hinterlassen Sie eine Rezension',
    },
  },
  sharingModal: {
    methods: {
      clipboard: {
        label: 'Link kopieren',
        success: 'Link in die Zwischenablage kopiert',
      },
      email: {
        label: 'E-Mail',
      },
      facebook: {
        label: 'Facebook',
      },
      messenger: {
        label: 'Messenger',
      },
      whatsApp: {
        label: 'WhatsApp',
      },
      x: {
        label: 'X',
      },
    },
    title: 'Teilen',
  },
  sortingOption: {
    default: 'Relevanz',
    id: {
      asc: '',
      desc: '',
    },
    name: {
      asc: '',
      desc: '',
    },
    price: {
      asc: 'Niedriger Preis',
      desc: 'Hoher Preis',
    },
    priority: {
      asc: '',
      desc: '',
    },
  },
  storeLocator: {
    filterPanel: {
      selectProduct: 'Wählen Sie das Produkt aus',
    },
    listingPanel: {
      loading: 'loading...',
      shopsCount: '',
      shopsCount_one: '{{count}} Verkaufsstelle',
      shopsCount_other: '{{count}} Verkaufsstellen',
    },
    modal: {
      desktopPhone: {
        content: 'Für weitere Informationen über {{name}}, kontaktieren Sie bitte {{phoneNumber}}',
        title: 'Kontaktieren Sie die Verkaufsstelle',
      },
      gallery: {
        title: 'Foto der Verkaufsstelle',
      },
      geolocationError: {
        content: 'Geolokalisierung verweigert',
        title: 'Karte kann nicht zentriert werden, Geolokalisierung verweigert',
      },
    },
    shopDetails: {
      closed: 'Geschlossen',
      cta: {
        call: 'Anrufen',
        directions: 'Wegbeschreibung',
      },
      description: 'Beschreibung',
      openingDays: {
        FRIDAY: 'Freitag',
        MONDAY: 'Montag',
        SATURDAY: 'Samstag',
        SUNDAY: 'Sonntag',
        THURSDAY: 'Donnerstag',
        TUESDAY: 'Dienstag',
        WEDNESDAY: 'Mittwoch',
      },
      socialNetwork: 'Social Network',
    },
    shopTile: {
      CLOSED: 'Geschlossen',
      OPEN: 'Geöffnet',
      closesAtDayTime: 'Schließt {{day}} um {{time}}',
      closesAtTime: 'Schließt um {{time}}',
      copyLink: 'Link kopieren',
      linkCopied: 'Link in die Zwischenablage kopiert',
      opensAtDayTime: 'Öffnet {{day}} um {{time}}',
      opensAtTime: 'Öffnet um {{time}}',
      share: 'Teilen',
    },
  },
  survey: {
    buttons: {
      complete: {
        label: 'Entdecken Sie das Ergebnis',
      },
      nextQuestion: {
        label: 'Weiter',
      },
      prevQuestion: {
        label: 'Zurück',
      },
      reset: {
        label: 'Zurücksetzen',
      }
    }
  },
  topSuggestionsBox: {
    categories: 'Kategorien',
    popularSearches: 'Beliebte Suchanfragen',
    recentSearches: 'Kürzliche Suchanfragen',
    showAllProducts: 'Entdecken Sie alle Produkte',
  },
  updateCustomerRequiredFieldsPage: {
    subtitle: 'Vervollständigen Sie Ihre Daten und starten Sie Ihren Einkauf im Shop',
    title: 'Hallo',
    titleWithName: 'Hallo {{name}}',
  },
  wishlist: {
    modal: {
      availabilityNotificationDisabled: 'Sie werden nicht mehr benachrichtigt, wenn das Produkt verfügbar ist.',
      availabilityNotificationEnabled: 'Sie werden benachrichtigt, sobald das Produkt wieder verfügbar ist!',
      buttons: {
        continueShopping: {
          label: 'Fahren Sie mit dem Einkauf fort',
        },
        goToWishlist: {
          label: 'Gehen Sie zur Wunschliste',
        },
      },
      productAdded: 'Das ausgewählte Produkt wurde der Wunschliste hinzugefügt.',
      productRemoved: 'Das ausgewählte Produkt wurde aus der Wunschliste entfernt.',
      title: 'Aktualisierung der Wunschliste',
    },
  },
  yup: {
    array: {
      length: 'Muss {{length}} Elemente enthalten',
      max: 'Muss eine Zahl kleiner als oder gleich {{max}} Elemente enthalten',
      min: 'Muss mindestens {{min}} Elemente enthalten',
    },
    boolean: {
      isValue: 'Muss {{value}} sein',
    },
    custom: {
      passwordConfirmationNotMatch: 'Passwortbestätigung falsch',
    },
    date: {
      max: 'Muss vor {{max}} liegen',
      min: 'Muss hinter {{min}} liegen',
    },
    mixed: {
      default: 'Feld ungültig',
      defined: 'Feld nicht definiert',
      notOneOf: 'Er darf nicht einer der folgenden Werte sein: {{values}}',
      notType: 'Es muss vom Typ `{{type}}` sein, aber der endgültige Wert war: {{value}}',
      oneOf: 'Es muss einer der folgenden Werte sein: {{values}}',
      required: 'Pflichtfeld',
    },
    number: {
      integer: 'Muss eine ganze Zahl sein',
      lessThan: 'Muss kleiner sein als {{less}}',
      max: 'Muss kleiner als oder gleich {{max}} sein',
      min: 'Muss größer als oder gleich {{min}} sein',
      moreThan: 'Muss größer sein als {{more}}',
      negative: 'Muss eine negative Zahl sein',
      positive: 'Muss eine positive Zahl sein',
    },
    object: {
      noUnknown: 'Das Feld hat nicht näher angegebene Schlüssel {{unknown}}',
    },
    string: {
      email: 'Ungültiges E-Mail-Format',
      length: 'Muss genau {{length}} Zeichen haben',
      lowercase: 'Muss eine klein geschriebene Zeichenkette sein',
      matches: 'Muss übereinstimmen mit "{{regex}}"',
      max: 'Darf höchstens {{max}} Zeichen enthalten',
      min: 'Muss mindestens {{min}} Zeichen enthalten',
      trim: 'Darf keine Leerzeichen enthalten',
      uppercase: 'Muss eine groß geschriebene Zeichenkette sein',
      url: 'Muss eine gültige URL sein',
      uuid: 'Muss ein gültiger UUID sein',
    },
  },
};
