import './css/style.scss';
import './i18n/i18n';

import { b2x } from '@b2x/react/src';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App';
import { BestPrice } from './BestPrice';
import { Breadcrumb } from './Breadcrumb';
import { Button, ButtonVariant } from './Button';
import { CartSku } from './CartSku';
import { Container } from './Container';
import { NewsletterForm } from './forms/NewsletterForm';
import { en } from './i18n/locales/en';
import { it } from './i18n/locales/it';
import { IconName, IconSize } from './Icon';
import { LoadingOverlay } from './LoadingOverlay';
import { OffcanvasHeader } from './Offcanvas';
import { ThemeColor } from './pages/TemplatePage';
import { ProductTile, ProductTileBasicInfo, ProductTileExtraInfo } from './ProductTile';
import { reportWebVitals } from './reportWebVitals';
import { ServiceContactsSubpage } from './ServiceContactsSubpage';

b2x.init<ThemeColor, ButtonVariant, IconName, IconSize>({
  api: {
    apiKey: 'GHEDHHJAABGIBIAJEFBBJH',
    productPopulate: {
      alternativeImages: true,
      assembledComponents: {
        componentSkus: {
          price: true,
          sku: {
            attributes: true,
            measurement: true,
            price: true,
            product: {
              attributeGroups: true,
              attributes: true,
            },
          },
        },
      },

      attributeGroups: true,
      attributes: true,
      content: true,
      offers: true,
      priceRange: true,
      reviews: true,
      skus: {
        alternativeImages: true,
        attributes: true,
        price: true,
      },
      summaryReviews: true,
      variantProducts: true,
    },
    productTilePopulate: {
      alternativeImages: true,
      attributes: true,
      offers: true,
      priceRange: true,
      skus: {
        alternativeImages: true,
        attributes: true,
        price: true,
      },
      variantProducts: true,
    },
    sessionOptions: {
      populate: {
        cart: {
          packs: {
            cartSkus: {
              giftCard: true,
              offers: true,
              sku: {
                attributes: true,
                product: {
                  attributes: true,
                },
              },
            },
          },
          shippingProfile: true,
        },
        customer: true,
        wishlist: { products: true, skus: { product: true } },
      },
    },
  },
  // cookiebot: {
  //   id: '1663843a-138a-415e-adf4-3201980ad7f3',
  // },
  defaultButtonVariant: 'primary',
  enableBestPrice: true,
  form: {
    buttons: {
      cancel: {
        defaultVariant: 'outline-primary',
      },
      submit: {
        defaultVariant: 'primary',
      },
    },
  },
  // googleMaps: {
  //   apiKey: 'AIzaSyATte7QvaIVMsXAP0YaJPdpKJIMGDTAK7I',
  // },
  i18n: {
    en: en,
    it: it,
  },

  icons: {
    arrow: {
      left: 'small-arrow-left',
    },
    close: 'delete',
    configurator: 'filter',
    download: 'small-arrow-down',
    info: 'info',
    logout: 'logout',
    passwordToggle: {
      hidden: 'hidden',
      visible: 'visible',
    },
  },

  installmentPaymentBanner: {
    scalapay: {
      enabled: true,
    },
  },

  preRelease: true,
  preselectFirstAvailableSku: true,
  preselectPreferredSku: true,
  productImageAspectRatio: 1,
  projectName: 'miacosmetics',
  ui: 'bs5',
  variants: {
    AddressFieldset: 'A',
    BestPrice: BestPrice,
    Breadcrumb: Breadcrumb,
    Button: Button,
    CartAddressFromAddressBookForm: 'A',
    CartAddressesForm: 'A',
    CartSku: CartSku,
    ChangePasswordForm: 'A',
    Container: Container,
    CustomerAddressForm: 'A',
    CustomerForm: 'C',
    GiftCardForm: 'A',
    InvoiceFieldset: 'A',
    LoadingOverlay: LoadingOverlay,
    LocaleForm: 'A',
    LoginForm: 'A',
    NewsletterForm: NewsletterForm,
    OffcanvasHeader: OffcanvasHeader,
    OrderDetails: 'A',
    OrderGuestForm: 'A',
    PriceBlock: 'A',
    ProductTile: ProductTile,
    ProductTileBasicInfo: ProductTileBasicInfo,
    ProductTileExtraInfo: ProductTileExtraInfo,
    ReviewFormModal: 'A',
    ServiceContactsSubpage: ServiceContactsSubpage,
    SimpleSearchForm: 'A',
  },
  whatsAppClickToChat: 3668272127,
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <b2x.router.BrowserRouter>
      <b2x.ModalsContext>
        <App />
      </b2x.ModalsContext>
    </b2x.router.BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
